import dayjs from 'dayjs';
import environment from '@/environment';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store';
import { AuthStateInterface } from '@/modules/auth/interfaces/authState';

const router = new VueRouter({
  mode: 'history',
  routes,
});

const allowedStates = [
  'login',
  'logout',
  'reset-password',
  'set-new-password',
];

router.beforeEach((to, from, next) => {
  console.debug('Before Transition from ' + from.name + ' to ' + to.name);

  let nextRoute: string | undefined;
  const authStateJson: string | null = environment.authLocalStorageKey
    ? localStorage.getItem(environment.authLocalStorageKey)
    : null;
  let authState: AuthStateInterface | null;
  if (authStateJson) {
    authState = JSON.parse(authStateJson);
    if (
      authState &&
      authState.accessToken &&
      authState.tokenExpiresIn &&
      authState.user
    ) {
      const loginDate = dayjs(authState.user?.loginDate).add(
        authState.tokenExpiresIn!,
        'second',
      );
      if (dayjs().isBefore(loginDate)) {
        store.commit('auth/updateUser', authState.user);
        store.commit('auth/updateTokenData', {
          accessToken: authState.accessToken,
          refreshToken: authState.refreshToken,
          tokenExpiresIn: authState.tokenExpiresIn,
        });
      } else {
        localStorage.removeItem(environment.authLocalStorageKey);
      }
    } else {
      localStorage.removeItem(environment.authLocalStorageKey);
    }
  }

  if (store.state && store.state.auth && store.state.auth.accessToken) {
    if (to && to.name === 'login') {
      nextRoute = '/';
    }
  } else {
    if (to && to.name && allowedStates.indexOf(to.name) === -1) {
      nextRoute = 'login';
    }
  }

  console.log('auth.lastPage', store.state.auth?.lastPage);
  console.log('to.name', to.name);
  if (store.state.auth?.lastPage && from.name && to.name !== 'login') {
    store.state.auth.lastPage = from.name;
  }

  if (to.meta) {
    const routeMeta: Record<string | number | symbol, any> = to.meta;
    if (routeMeta.requiresBrokerRole && to.name === 'brokers') {
      const userRoles: string[] | undefined = store.state.auth?.user?.roles;
      let isBroker = false;
      userRoles?.map((role: string) => {
        role === 'ROLE_USER' ? (isBroker = true) : (isBroker = false);
      });
      isBroker ? next() : next('/');
    }
  }

  next(nextRoute);
});

router.afterEach((to, from) => {
  console.debug('Successfull Transition from ' + from.name + ' to ' + to.name);
});

export default router;
